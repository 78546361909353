<template>
    <div class="">
        <QuizellButton @click="OpenModal()">Manage Profit Margin</QuizellButton>
      <Modal
        ref="FrightQuotModal"
        id="FrightQuotModal"
        title="<span class='px-2'> Manage Profit Margin </span>"
        size="md">
  
        <div class="">
            <div class="" v-if="getMarginLoading">
                <div 
        class="integrationImgae bg-preload mt-3" v-for="index in 3" :key="index"
        style="height: 100px; width: 100%; border-radius: 14px">
        <div class="into-preload"></div>
      </div>
            </div>
          <form @submit.prevent="save()" v-else>
            <div v-if="valueMargin.length" class="" style="max-height: 410px;
            scroll-behavior: smooth;
    overflow-y: scroll;
    overflow-x: hidden;">
            <div class="position-relative" v-for="(margin,index) in valueMargin" :key="index">
            <div @click="removeElement(index)" class="close-button" title="Close"><i class="fa fa-times" aria-hidden="true"></i></div>
            <div :class="[index!=0?'mt-3':'']"  style="background: #f7f7f7;
    padding: 20px 0;
    border-radius: 12px;
    ">
<div class="row px-5" >
           
           <div class="col-12 ">
             <label for="" class="m-0" style="color: #73738d; font-size: 14px"
               >Margin Value:</label
             >
                  <div class="d-flex quizell-type justify-content-center align-items-center">
         <input type="number" required class="w-100 quizell-form-control px-2"  placeholder="Margin Value"   v-model.number="margin.value"
   >
   <div class="px-2">
       <select required v-model="margin.type" class="select-value">
        <option value="percentage">%</option>
         <option value="price">$</option>
       </select>
   </div>
     </div>
            
           </div>
           <div class="col-12 mt-2">
             <label for="" class="m-0" style="color: #73738d; font-size: 14px"
               >Price Between:</label
             >
             <div class="row">
               <div class="col-6">
                   <div class="d-flex quizell-type justify-content-center align-items-center">
         <input type="number" required class="w-100 quizell-form-control px-2"  placeholder="From"   v-model.number="margin.in_between[0]"
   >
         
     </div>
               </div>
               <div class="col-6">
                   <div class="d-flex quizell-type justify-content-center align-items-center">
         <input type="number" required :min="margin.in_between[0]" class="w-100 quizell-form-control px-2"  placeholder="To"    v-model.number="margin.in_between[1]"
   >
         
     </div>
               </div>
             </div>
                 
            
           </div>
       
         </div>    
</div>
</div> 
</div>
<template v-else>

<div class="d-flex justify-content-center align-items-center">

<EmptyTable >
<template #title>
Manage Margin
</template>
<template #description>
<div class=""></div>
</template>
</EmptyTable>
</div>

</template>
          <div class="d-flex mt-3 justify-content-end" style="gap:10px">
            <b-button @click="pushMargin()" variant="link" class="font-weight-bold linkButton">+ Add</b-button>
            <QuizellButton :isLoading="isLoading" type="submit">Update</QuizellButton>
          </div>
          </form>
        </div>
      </Modal>
    </div>
  </template>
  <script>
  import EmptyTable from "../../Dashboard/Layout/EmptyTable.vue";
  import QuizellButton from "../../Dashboard/Layout/Button.vue";
  import Modal from "../../Dashboard/Layout/Modal.vue";
  import axios from 'axios'
  export default {
    props: {
  
    },

    components: {
      QuizellButton,
  EmptyTable,
    
      // FilterBox,
      Modal,
    },
  
    data() {
      return {
        isLoading:false,
        valueMargin:[],
        getMarginLoading:false,
      };
    },
    computed: {
 
       areAllFieldsValid() {
      for (const item of this.valueMargin) {
        if (
           item.type == '' ||
           item.value == '' ||
           item.in_between[0] == '' ||
           item.in_between[1] == ''
        ) {
          return false;
        }
    }
      return true;
       }
  },
    methods: {
        removeElement(index){
            this.valueMargin.splice(index, 1);
        },
        pushMargin(){
            this.valueMargin.push(
                {
            "type": "percentage",
            "value": '',
            "in_between": [
                '',
                ''
            ]
        }
            )
        },
      OpenModal(){
    
   this.$refs.FrightQuotModal.open();
   this.getMargins()
      },
      CloseModal(){
   this.$refs.FrightQuotModal.close();
      },
     
     async getMargins(){
        this.getMarginLoading=true;
        try {
            const response = await axios.get('/custom-clients/d4w/price-margin')
            if(response.status)
            {
                this.valueMargin=response.data.data.margins
            }
        }
        catch (error) {
          if (error) {
            this.$toasted.show("Error occurred", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
          }
          throw error;
        } finally {
          this.getMarginLoading = false;
        }
    },
     async save() {
     
       this.isLoading=true;
      try {
        if(!this.areAllFieldsValid)
        {
            this.$toasted.show("Please fill all the fields.", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
            return 
        }
          const response = await axios.post(`/custom-clients/d4w/price-margin`, {margins:this.valueMargin});
          if (response.status == 200) {
            this.CloseModal();
            this.$toasted.show("Margin Price Updated Successfully", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
          }
        } catch (error) {
          if (error) {
            this.$toasted.show("Error occured", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
          }
          throw error;
        } finally {
          this.isLoading = false;
        }
         
        }
      
    }
  };
  </script>
  <style scoped>
  .linkButton:hover{
    text-decoration: none;
  }
  .close-button{
    position: absolute;
    right: 6px;
    top: 5px;
    background: #E91E63;
    color: wheat;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    font-size: 12px;
  }
  .select-value{
    border: navajowhite;
    outline: navajowhite;
    color: grey;
    height: 100%;

  }
  .checkbox >>> .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #ffa201;
    background-color: #ffa201 !important;
  }
  .checkbox >>> .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem #ffa201;
  }
  .loader {
    min-height: 40px;
  }
  .customPagination >>> li > button {
    color: #4d1b7e;
  }
  
  .customPagination >>> li.active > button {
    color: white;
    background-color: #4d1b7e !important;
    border-color: #4d1b7e;
  }
  .customPagination >>> li > button:hover {
    color: white;
    background-color: #6b4690 !important;
    border-color: #4d1b7e;
  }
  .upload-image {
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
    color: #ffa201;
    font-size: 14px;
    cursor: pointer;
  }
  .upload-image-disabled {
    color: #73738d;
    font-size: 14px;
  }
  .file-upload-option >>> .btn {
    background-color: #7a4fa3;
    padding: 6px 8px;
  }
  .file-upload-option >>> .active,
  .file-upload-option >>> .btn:focus,
  .file-upload-option >>> .btn:active {
    background-color: #4d1b7e !important;
  }
  .file-upload-option >>> .active:focus {
    box-shadow: 0 0 0 0.2rem #4d1b7e !important;
  }
  .custom-switch
    >>> .custom-control-input:checked
    ~ .custom-control-label::after {
    background-color: #ffa201 !important;
    border-color: #ced3d7 !important;
  }
  .custom-switch
    >>> .custom-control-input:checked
    ~ .custom-control-label::before {
    background-color: #e0dee2 !important;
    border-color: #ced3d7 !important;
  }
  
  .custom-control-input.radio:checked ~ .custom-control-label::before {
    background-color: #0397cc !important;
    color: #0397cc !important;
    border-color: #0397cc !important;
  }
  .quizell-type:focus-within {
      color: #495057;
    background-color: #fff;
    border-color: #f7f6f9;
    outline: 0;
    box-shadow: 0 0 0 0.1rem #4d1b7e;
  }
  .quizell-form-control{
  border: none;
  height:48px;
  flex: 1;
  border-radius: 10px;
  }
  .quizell-form-control:focus-visible{
      border: none!important;
      outline: none!important;
  }
  .quizell-type{
      /* white */
  transition: 0.3s ease all;
  background: #FFFFFF;
  /* greyLight */
  
  border: 1px solid #D2D8E0;
  border-radius: 8px;
  }
  </style>
  